@import url("./style/custom-bootstrap.scss");

.cursor-pointer {
  cursor: pointer;
}
.hover-time {
  transition: ease-in-out 100ms;
}
.hover-time:hover {
  scale: 1.02;
}

.tamanho-card-resultado {
  width: 24rem;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .tamanho-card-resultado{
    width: 45rem;
  }
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
