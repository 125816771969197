$primary: #048b48;
$secondary: #e6b326;
$danger: rgb(229, 59, 16);
$success: #03A64A;
$light: #f8f9fa;

.btn {
  color: $light;
  &:hover,
  &:active,
  &:focus,
  &:disabled {
    color: $light;
    &:active,
    &:focus {
      color: $light;
    }
  }
}

h1,
h2,
h3,
h6 {
  font-weight: bold;
}

a {
  font-weight: 600;
}

.btn-light {
  color: black;
  &:hover,
  &:active,
  &:focus,
  &:disabled {
    color: black;
    &:active,
    &:focus {
      color: black;
    }
  }
}

.btn-outline-secondary {
  color: $secondary;
  &:hover,
  &:active,
  &:focus,
  &:disabled {
    color: $secondary;
    background-color: $light;
    &:active,
    &:focus {
      color: $secondary;
      background-color: $light;
    }
  }
}
.btn-outline-danger {
  color: $danger;
  &:hover,
  &:active,
  &:focus,
  &:disabled {
    color: $light;
    &:active,
    &:focus {
      color: $light;
    }
  }
}

.pointer {
  cursor: pointer;
}