$theme-colors: (
  primary: #048b48,
  primary-main: #014925,
  success: #06d66e,
  secondary: #e6b326,
  danger: rgb(229, 59, 16),
  info: #0d6efd,
  dark: rgb(33, 33, 33),
  light: #f8f9fa
);

@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;700&display=swap");

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  list-style-type: none;
}

form-check-input {
  background-color: transparent;
  background: transparent;
}

.form-check-input:checked {
  background-color: #e6b326;
  border-color:#e6b326;
  color: #e6b326;
  outline: none;
  box-shadow: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  font-family: "Poppins", sans-serif;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  text-decoration: none;
    // -webkit-user-select: none;
  // /* Safari */
  // -ms-user-select: none;
  // /* IE 10 and IE 11 */
  // user-select: none;
}

input::placeholder {
  font-size: 14px; /* Altere o tamanho da fonte aqui */
}